/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import Cookies from 'universal-cookie';
import "./src/styles/global.css"

const cookies = new Cookies();

export const onRouteUpdate = ({ location }) => {
  if (location.pathname === "/news/") {
    console.log("SET")
    cookies.set("newsNotification", true, { path: "/" })
  }
}
